import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import "./Testimonials.css"

import Eric from "../../assests/eric.jfif";
import Katie from "../../assests/katie.jfif";
import Jake from "../../assests/jake.jfif";


const data = [
  {
    img: Katie,
    name: "Katie McMillin",
    company: "General Assembly",
    position: "Instructor Associate",
    review: "He is an ambitious, hard-working, and talented coder as well as a great teacher and a very positive influence on class culture. Any cohort would be lucky to have him."
  },
  {
    img: Jake,
    name: "Jacob Komarow",
    company: "EcomByJake",
    position: "CEO",
    review: "...one of the best employees I've ever had. Any team would lucky to have him!"
  },
  {
    img: Eric,
    name: "Eric Fithian",
    company: "General Assembly",
    position: "Lead Instructor",
    review: "He is the type of student who is not only ahead of most technically with his skill set but who clearly understands what it means to teach others and help them get to where he is if they have a lower initial baseline."
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>What do people say about me?</h5>
      <h2>Testimonials</h2>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="container testimonials__container">
        {data.map(({ img, name, company, position, review }, index) => {
          return <SwiperSlide className="testimonial" key={index}>
            <div className="client__img__wrapper">
              <img src={img} alt="Ali doggy" className="client__img" />
            </div>
            <h5 className="client__name">{name}</h5>
            <small><em>{position}</em> | {company}</small>
            <small className="client__review">{review}</small>
          </SwiperSlide>
        })}
      </Swiper>
      <p className="scroll_down">&larr; DRAG &rarr;</p>
    </section>
  )
}

export default Testimonials