import { useRef } from "react";
import { AiOutlineMail, AiOutlineWhatsApp, AiOutlineLinkedin } from "react-icons/ai";
import emailjs from 'emailjs-com';

import "./Contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_gto1gp7', 'template_00mhtnn', form.current, '7dlyqOCYXJrX1kujK')

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Please, reach out!</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>dev.howey@gmail.com</h5>
            <a href="mailto:dev.howey@gmail.com" target="_blank" rel="noreferrer">Send me an email</a>
          </article>
          <article className="contact__option">
            <AiOutlineLinkedin className="contact__option-icon"/>
            <h4>LinkedIn</h4>
            <h5>in/devhowey</h5>
            <a href="https://www.linkedin.com/in/dev-howey/" target="_blank" rel="noreferrer">Connect with me</a>
          </article>
          <article className="contact__option">
            <AiOutlineWhatsApp className="contact__option-icon"/>
            <h4>WhatsApp</h4>
            <h5>+1 (561) 323-1982</h5>
            <a href="https://api.whatsapp.com/send?phone=15613231982" target="_blank" rel="noreferrer">Send me a message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="name"></label>
          <input type="text" name="name" placeholder="What's your name?" required />
          <label htmlFor="email"></label>
          <input type="email" name="email" placeholder="What's your email?" required />
          <label htmlFor="message"></label>
          <textarea name="message" rows="7" placeholder="What can I do for you?" required></textarea>
          <button type="submit" className="btn-flashy">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact