import { Link, NavLink } from "react-router-dom";
import { AiOutlineFundProjectionScreen, AiOutlineHome, AiOutlineProject, AiOutlineCode } from "react-icons/ai";
import { FaUserAstronaut } from "react-icons/fa";
import { RiServiceLine } from "react-icons/ri";
import { MdOutlineMessage, MdOutlineRoomService } from "react-icons/md";

import HeaderSocials from "./HeaderSocials";
import "./Sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebar__container">
      <Link className="sidebar__logo__container" to="/">
        <AiOutlineCode className="sidebar__logo" />
        <h3 className="sidebar__subheading">devhowey</h3>
      </Link>
      <HeaderSocials />
      <nav className="sidebar__nav">
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item home__link"
          to="/"
        >
          <AiOutlineHome />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item about__link"
          to="/about"
        >
          <FaUserAstronaut />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item experience__link"
          to="/experience"
        >
          <AiOutlineProject />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item projects__link"
          to="/projects"
        >
          <AiOutlineFundProjectionScreen />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item services__link"
          to="/services"
        >
          <MdOutlineRoomService />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item testimonials__link"
          to="/testimonials"
        >
          <RiServiceLine />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sidebar__nav-item contact__link"
          to="/contact"
        >
          <MdOutlineMessage />
        </NavLink>
      </nav>
    </div>
  )
}

export default Sidebar;