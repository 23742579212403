import { SiCheckio } from "react-icons/si"

import "./Services.css";

const webDesign = [
  "Band Pages",
  "Restaurant Pages",
  "Artist Pages",
  "Portfolio Pages",
  "Landing Pages",
];
const webDev = [
  "Servers with Express.js",
  "Database Design",
  "Full Stack Development",
  "React Migrations",
];
const techCopy = [
  "Search Engine Optimization",
  "Blog Maintenance",
  "Content Creation",
  "Keyword Research",
  "Content Updates",
  "Technial Documentation",
];

const Services = () => {
  return (
    <section id="services">
      <h5>What can I do for you?</h5>
      <h2>Services Offered</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Web Design</h3>
          </div>
          <ul className="service__list">
            {webDesign.map((skill, index) => {
              return <li key={index}>
                <SiCheckio className="service__list-icon" />
                <p>{skill}</p>
              </li>
            })}
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            {webDev.map((skill, index) => {
              return <li key={index}>
                <SiCheckio className="service__list-icon" />
                <p>{skill}</p>
              </li>
            })}
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Technical &amp; Copy</h3>
          </div>
          <ul className="service__list">
            {techCopy.map((skill, index) => {
              return <li key={index}>
                <SiCheckio className="service__list-icon" />
                <p>{skill}</p>
              </li>
            })}
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services