import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineFundProjectionScreen, AiOutlineProject } from "react-icons/ai";
import { FaUserAstronaut } from "react-icons/fa";
import { RiServiceLine } from "react-icons/ri";
import { MdOutlineRoomService, MdOutlineMessage } from "react-icons/md";

import "./Nav.css";

const Nav = () => {
  const [active, setActive] = useState('#home');

  return (
    <nav className="mobile__nav">
      <Link to="/"
        onClick={() => setActive('#home')}
        className={active === '#home' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">Home</span>
        <AiOutlineHome />
      </Link>
      <Link to="/about"
        onClick={() => setActive('#about')}
        className={active === '#about' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">About</span>
        <FaUserAstronaut />
      </Link>
      <Link to="/experience"
        onClick={() => setActive('#experience')}
        className={active === '#experience' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">Experience</span>
        <AiOutlineProject />
      </Link>
      <Link to="/projects"
        onClick={() => setActive('#projects')}
        className={active === '#projects' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">Projects</span>
        <AiOutlineFundProjectionScreen />
      </Link>
      <Link to="/services"
        onClick={() => setActive('#services')}
        className={active === '#services' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">Services</span>
        <MdOutlineRoomService />
      </Link>
      <Link to="/testimonials"
        onClick={() => setActive('#testimonials')}
        className={active === '#testimonials' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">Services</span>
        <RiServiceLine />
      </Link>
      <Link to="/contact"
        onClick={() => setActive('#contact')}
        className={active === '#contact' ? 'active' : ''}
        aria-hidden="true">
        <span className="is-hidden">Contact</span>
        <MdOutlineMessage />
      </Link>
    </nav>
  )
}

export default Nav