import { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

import Resume from '../../assests/devhowey_cv.pdf';

const CTA = () => {
  const ctaRef = useRef();

  useEffect(() => {
    ctaRef.current.focus();
  }, []);

  return (
    <div className="cta">
      <button href={Resume} download className="btn-flashy">Download Resume</button>
      <Link className="btn-flashy btn-primary" to={"/contact"} ref={ctaRef}>Let's Talk</Link>
    </div>
  )
}

export default CTA;