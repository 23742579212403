import "./Header.css"

import CTA from "./CTA";
import ME from "../../assests/me&ali.png"

const Header = () => {
  return (
    <>
      <header id="home">
        <div className="container header__container">
          <h5>Michael David Howey</h5>
          <h1>devhowey</h1>
          <h5 className="text-light">Software Engineer</h5>
          <CTA />
          <div className="me">
            <img src={ME} alt="Michael Howey" />
          </div>
        </div>
      </header>
    </>
  )
}

export default Header