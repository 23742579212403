import "./Projects.css";

import PikChu from "../../assests/pikchu-login.png";
import Gotchi from "../../assests/gotchibuddy-gameplay.png";
import HoweyTube from "../../assests/howeytube.png";
import Wayfarer from "../../assests/wayfarer-full-app.png";
import Portfolio from "../../assests/first-portfolio.png";
import edikly from "../../assests/figma-edikly.png";
import NodePic from "../../assests/nodejs.png";
import Flutter from "../../assests/flutter.png";

const data = [
  {
    img: HoweyTube,
    title: "HoweyTube",
    tech: "React, YouTube Data v3 API",
    github: "https://github.com/mdhowey/youtube-clone",
    demo: "https://howeytube.netlify.app/",
  },
  {
    img: edikly,
    title: "edik.ly | In-Progress",
    tech: "React, Express, Node, PostgreSQL",
    github: "https://github.com/mdhowey/edik.ly",
    demo: null,
  },
  {
    img: Portfolio,
    title: "First Portfolio Site",
    tech: "React, Sass, Express, Node",
    github: "https://github.com/mdhowey/devhowey",
    demo: "https://devhowey.netlify.app/",
  },
  {
    img: NodePic,
    title: "URL Monitor",
    tech: "Node, CSS",
    github: "https://github.com/mdhowey/url-monitor",
    demo: null,
  },
  {
    img: Wayfarer,
    title: "Wayfarer",
    tech: "Django, Python, PostgreSQL",
    github: "https://github.com/mdhowey/wayfarer",
    demo: "https://wayfarer-team-1.herokuapp.com/",
  },
  {
    img: PikChu,
    title: "PikChu | Deployment",
    tech: "Express, Node, MongoDB",
    github: "https://github.com/mdhowey/pikchu",
    demo: null,
  },
  {
    img: Gotchi,
    title: "Gotchi Buddy",
    tech: "JavaScript, HTML, CSS",
    github: "https://github.com/mdhowey/gotchi-buddy",
    demo: "https://mdhowey.github.io/gotchi-buddy/",
  },
  {
    img: Flutter,
    title: "Weather App",
    tech: "Flutter, Dart, Material UI",
    github: "https://github.com/mdhowey/clima",
    demo: null,
  },
]

const Projects = () => {
  return (
    <section id="projects">
      <h5>Here's some of my work</h5>
      <h2>Projects</h2>
      <div className="container projects__container">
        {data.map(({ img, title, tech, github, demo }, index) => {
          return <article className="project" key={index}>
            <img src={img} alt={title} className="project-image" />
            <h3>{title}</h3>
            <small>{tech}</small>
            <div className="project-cta">
              <a href={github} className="btn-flashy" target="_blank" rel="noreferrer">GitHub</a>
              {demo !== null ? <a href={demo} className="btn-flashy btn-primary" target="_blank" rel="noreferrer">Live</a> : null}
            </div>
          </article>
        })}
        <p className="scroll__down">Scroll Down</p>
      </div>
    </section>
  )
}

export default Projects