import { AiOutlineLinkedin, AiFillGithub, AiFillMediumCircle } from 'react-icons/ai';

const HeaderSocials = () => {
  return (
    <div className="social__icons__container">
      <a href="https://www.linkedin.com/in/dev-howey/" target="_blank" rel="noreferrer" aria-hidden="true">
        <span className="is-hidden">LinkedIn</span>
        <AiOutlineLinkedin className="social__icon" />
      </a>
      <a href="https://github.com/mdhowey" target="_blank" rel="noreferrer" aria-hidden="true">
        <span className="is-hidden">GitHub</span>
        <AiFillGithub className="social__icon" />
      </a>
      <a href="https://medium.com/@dev.howey" target="_blank" rel="noreferrer" aria-hidden="true">
        <span className="is-hidden">Medium</span>
        <AiFillMediumCircle className="social__icon" />
      </a>
    </div>
  )
}

export default HeaderSocials;
