import { BsBookmarkCheck } from "react-icons/bs";

import "./Experience.css"

const frontData = [
  {
    skill: "HTML",
    time: "2+ Years",
  },
  {
    skill: "CSS",
    time: "2+ Years",
  },
  {
    skill: "JavaScript",
    time: "2+ Years",
  },
  {
    skill: "Bootstrap",
    time: "2+ Years",
  },
  {
    skill: "Sass",
    time: "1+ Year",
  },
  {
    skill: "React",
    time: "1+ Year",
  },
  {
    skill: "Flutter",
    time: "6+ Months",
  },
];

const backData = [
  {
    skill: "Node JS",
    time: "2+ Years",
  },
  {
    skill: "MongoDB",
    time: "1+ Years",
  },
  {
    skill: "PostgreSQL",
    time: "1+ Years",
  },
  {
    skill: "Python",
    time: "2+ Years",
  },
  {
    skill: "Django",
    time: "1+ Year",
  },
  {
    skill: "Java",
    time: "2+ Years",
  },
];

const Experience = () => {
  return (
    <section id="experience">
      <h5>What can I do?</h5>
      <h2>Experience</h2>
      <div className="experience__container">
        {/* FRONTEND */}
        <div className="experience__frontend">
          <h3>Front-end</h3>
          <div className="experience__content">
            {frontData.map(({ skill, time }, index) => {
              return <article className="experience__details" key={index}>
                <BsBookmarkCheck className="experience__details-icon" />
                <div>
                  <h4>{skill}</h4>
                  <small className="text-small">{time}</small>
                </div>
              </article>
            })}
          </div>
        </div>
        {/* BACKEND */}
        <div className="experience__backend">
          <h3>Back-end</h3>
          <div className="experience__content">
            {backData.map(({ skill, time }, index) => {
              return <article className="experience__details" key={index}>
                <BsBookmarkCheck className="experience__details-icon" />
                <div>
                  <h4>{skill}</h4>
                  <small className="text-small">{time}</small>
                </div>
              </article>
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience;