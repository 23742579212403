import { Link } from 'react-router-dom';
import { GiNetworkBars } from "react-icons/gi"
import { GrWorkshop, GrCode } from "react-icons/gr"

import "./About.css"

import Sidebar from "components/sidebar/Sidebar";
import UrsuandMe from "../../assests/devhowey_about.jpg"

const data = [
  {
    icon: <GiNetworkBars className="about__icon" />,
    title: "Experience",
    subheading: "1+ Year Working",
  },
  {
    icon: <GrWorkshop className="about__icon" />,
    title: "Students",
    subheading: "120+ Students Taught",
  },
  {
    icon: <GrCode className="about__icon" />,
    title: "Projects",
    subheading: "20+ Completed Projects",
  }
];

const About = () => {
  return (
    <>
      <Sidebar />
      <section id="about">
        <h5>Get to know Howey</h5>
        <h2>About Me</h2>
        <div className="container about__container">
          <div className="about__me">
            <div className="about__me-image">
              <img src={UrsuandMe} alt="Ursula and Michael" />
            </div>
          </div>
          <div className="about__content">
            <div className="about__cards">
              {data.map(({ icon, title, subheading }, index) => {
                return <article className="about__card" key={index}>
                  {icon}
                  <h5>{title}</h5>
                  <small>{subheading}</small>
                </article>
              })}
            </div>
            <p>A Minnesotan in Mexico who enjoys finding patterns in syntax, both in spoken and programming languages. As an English teacher turned software engineer, my perspective allows me to break down challenging concepts for others.</p>
          </div>
          <Link className="btn-flashy btn-primary center__text" to={"/contact"}>Let's Talk</Link>
        </div>
      </section>
    </>
  )
}

export default About